import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./assets/css/EmployeeDashboard.css";

export default function EmployeeDashboard() {
  const [data, setData] = useState({
    events: [],
    users: [],
    products: [],
    feedbacks: [],
  });
  const [error, setError] = useState("");
  const [activeTab, setActiveTab] = useState("events");
  const [filters, setFilters] = useState({
    brand: "",
    eventCode: "",
    eventName: "",
    startDate: "",
    endDate: "",
    firstName: "",
    lastName: "",
    userEmail: "",
    productName: "",
    serialNumber: "",
    feedbackUser: "",
    feedbackProduct: "",
    feedbackRating: "",
  });
  const navigate = useNavigate();

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get("/api/employee_dashboard_data", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setData(response.data);
      } catch (error) {
        setError("Failed to load dashboard data");
      }
    };
    fetchDashboardData();
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/employee-login");
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const filteredEvents = () => {
    const { brand, eventCode, eventName, startDate, endDate } = filters;
    return data.events.filter((event) => {
      const eventBrandMatches = event.brand
        .toLowerCase()
        .includes(brand.toLowerCase());
      const eventCodeMatches = event.code
        .toLowerCase()
        .includes(eventCode.toLowerCase());
      const eventNameMatches = event.name
        .toLowerCase()
        .includes(eventName.toLowerCase());
      const eventDateMatches =
        (!startDate || new Date(event.date) >= new Date(startDate)) &&
        (!endDate || new Date(event.date) <= new Date(endDate));
      return (
        eventBrandMatches &&
        eventCodeMatches &&
        eventNameMatches &&
        eventDateMatches
      );
    });
  };

  const filteredUsers = () => {
    const { firstName, lastName, userEmail } = filters;
    return data.users.filter((user) => {
      const firstNameMatches = user.first_name
        .toLowerCase()
        .includes(firstName.toLowerCase());
      const lastNameMatches = user.last_name
        .toLowerCase()
        .includes(lastName.toLowerCase());
      const emailMatches = user.email
        .toLowerCase()
        .includes(userEmail.toLowerCase());
      return firstNameMatches && lastNameMatches && emailMatches;
    });
  };

  const filteredProducts = () => {
    const { productName, serialNumber } = filters;
    return data.products.filter((product) => {
      const nameMatches = product.name
        .toLowerCase()
        .includes(productName.toLowerCase());
      const serialMatches = product.serial_number
        .toLowerCase()
        .includes(serialNumber.toLowerCase());
      return nameMatches && serialMatches;
    });
  };

  const filteredFeedbacks = () => {
    const { feedbackUser, feedbackProduct, feedbackRating } = filters;
    return data.feedbacks.filter((feedback) => {
      const userMatches = feedback.user.toLowerCase().includes(feedbackUser);
      const productMatches = feedback.product
        .toLowerCase()
        .includes(feedbackProduct);
      const ratingMatches = feedback.rating.toString().includes(feedbackRating);
      return userMatches && productMatches && ratingMatches;
    });
  };

  const exportToCSV = (data, filename) => {
    const csvContent =
      "data:text/csv;charset=utf-8," +
      [
        Object.keys(data[0]).join(","),
        ...data.map((row) => Object.values(row).join(",")),
      ].join("\n");
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const renderTable = (dataType) => {
    switch (dataType) {
      case "events":
        return (
          <div>
            <h2>Events</h2>
            <div style={{ marginBottom: "20px" }}>
              <input
                type="text"
                name="brand"
                value={filters.brand}
                onChange={handleFilterChange}
                placeholder="Filter by Brand (starts with)"
                style={{ marginRight: "10px", padding: "8px" }}
              />
              <input
                type="text"
                name="eventCode"
                value={filters.eventCode}
                onChange={handleFilterChange}
                placeholder="Filter by Event Code"
                style={{ marginRight: "10px", padding: "8px" }}
              />
              <input
                type="text"
                name="eventName"
                value={filters.eventName}
                onChange={handleFilterChange}
                placeholder="Filter by Event Name"
                style={{ marginRight: "10px", padding: "8px" }}
              />
              <input
                type="date"
                name="startDate"
                value={filters.startDate}
                onChange={handleFilterChange}
                style={{ marginRight: "10px", padding: "8px" }}
              />
              <input
                type="date"
                name="endDate"
                value={filters.endDate}
                onChange={handleFilterChange}
                style={{ marginRight: "10px", padding: "8px" }}
              />
            </div>
            <br/>
            <div>
              <button
                onClick={() => exportToCSV(filteredEvents(), "events.csv")}
                className="export-button"
              >
                Export to CSV
              </button>
            </div>
            <table className="records-table" style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th>Brand</th>
                  <th>Code</th>
                  <th>Name</th>
                  <th>Date</th>
                  <th>Location</th>
                </tr>
              </thead>
              <tbody>
                {filteredEvents().map((event) => (
                  <tr key={event.id}>
                    <td>{event.brand}</td>
                    <td>{event.code}</td>
                    <td>{event.name}</td>
                    <td>{event.date}</td>
                    <td>{event.location}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        );
      case "users":
        return (
          <div>
            <h2>Users</h2>
            <div style={{ marginBottom: "20px" }}>
              <input
                type="text"
                name="firstName"
                value={filters.firstName}
                onChange={handleFilterChange}
                placeholder="Filter by First Name"
                style={{ marginRight: "10px", padding: "8px" }}
              />
              <input
                type="text"
                name="lastName"
                value={filters.lastName}
                onChange={handleFilterChange}
                placeholder="Filter by Last Name"
                style={{ marginRight: "10px", padding: "8px" }}
              />
              <input
                type="email"
                name="userEmail"
                value={filters.userEmail}
                onChange={handleFilterChange}
                placeholder="Filter by Email"
                style={{ marginRight: "10px", padding: "8px" }}
              />
            </div>
            <div>
              <button
                onClick={() => exportToCSV(filteredUsers(), "users.csv")}
                className="export-button"
              >
                Export to CSV
              </button>
            </div>
            <table className="records-table" style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Email</th>
                </tr>
              </thead>
              <tbody>
                {filteredUsers().map((user) => (
                  <tr key={user.id}>
                    <td>{user.first_name}</td>
                    <td>{user.last_name}</td>
                    <td>{user.email}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        );
      case "products":
        return (
          <div>
            <h2>Products</h2>
            <div style={{ marginBottom: "20px" }}>
              <input
                type="text"
                name="productName"
                value={filters.productName}
                onChange={handleFilterChange}
                placeholder="Filter by Product Name"
                style={{ marginRight: "10px", padding: "8px" }}
              />
              <input
                type="text"
                name="serialNumber"
                value={filters.serialNumber}
                onChange={handleFilterChange}
                placeholder="Filter by Serial Number"
                style={{ marginRight: "10px", padding: "8px" }}
              />
            </div>
            <div>
              <button
                onClick={() => exportToCSV(filteredProducts(), "products.csv")}
                className="export-button"
              >
                Export to CSV
              </button>
            </div>
            <table className="records-table" style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Serial Number</th>
                </tr>
              </thead>
              <tbody>
                {filteredProducts().map((product) => (
                  <tr key={product.id}>
                    <td>{product.name}</td>
                    <td>{product.serial_number}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        );
      case "feedbacks":
        return (
          <div>
            <h2>Feedbacks</h2>
            <div style={{ marginBottom: "20px" }}>
              <input
                type="text"
                name="feedbackUser"
                value={filters.feedbackUser}
                onChange={handleFilterChange}
                placeholder="Filter by User ID"
                style={{ marginRight: "10px", padding: "8px" }}
              />
              <input
                type="text"
                name="feedbackProduct"
                value={filters.feedbackProduct}
                onChange={handleFilterChange}
                placeholder="Filter by Product ID"
                style={{ marginRight: "10px", padding: "8px" }}
              />
              <input
                type="text"
                name="feedbackRating"
                value={filters.feedbackRating}
                onChange={handleFilterChange}
                placeholder="Filter by Rating"
                style={{ marginRight: "10px", padding: "8px" }}
              />
            </div>
            <div>
              <button
                onClick={() => exportToCSV(filteredFeedbacks(), "feedbacks.csv")}
                className="export-button"
              >
                Export to CSV
              </button>
            </div>
            <table className="records-table" style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th>User</th>
                  <th>Product</th>
                  <th>Body</th>
                  <th>Rating</th>
                </tr>
              </thead>
              <tbody>
                {filteredFeedbacks().map((feedback) => (
                  <tr key={feedback.id}>
                    <td>{feedback.user}</td>
                    <td>{feedback.product}</td>
                    <td>{feedback.body}</td>
                    <td>{feedback.rating}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="dashboard" style={{ width: "90%", margin: "0 auto" }}>
      <div className="dashboard-header">
        <button
          onClick={handleLogout}
          style={{
            padding: "10px 20px",
            fontSize: "16px",
            cursor: "pointer",
            backgroundColor: "#07296a",
            color: "white",
            border: "none",
            borderRadius: "5px",
            position: "absolute",
            top: "10px",
            right: "10px",
          }}
        >
          Logout
        </button>
      </div>

      {error && <div className="error-message">{error}</div>}

      <div className="tabs" style={{ marginTop: "30px" }}>
        <button
          className={activeTab === "events" ? "active" : ""}
          onClick={() => setActiveTab("events")}
        >
          Events
        </button>
        <button
          className={activeTab === "users" ? "active" : ""}
          onClick={() => setActiveTab("users")}
        >
          Users
        </button>
        <button
          className={activeTab === "products" ? "active" : ""}
          onClick={() => setActiveTab("products")}
        >
          Products
        </button>
        <button
          className={activeTab === "feedbacks" ? "active" : ""}
          onClick={() => setActiveTab("feedbacks")}
        >
          Feedback
        </button>
      </div>
      <section style={{ marginTop: "20px" }}>{renderTable(activeTab)}</section>
    </div>
  );
}
