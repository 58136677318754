// store.js
import React, { useEffect, createContext, useContext, useState } from "react";
import axios from "axios";
const AppContext = createContext();

export function AppProvider({ children }) {
  const [appState, setAppState] = useState("loading");
  const [logoRedirectUrl, setLogoRedirectUrl] = useState("");
  const [productToken, setProductToken] = useState("");
  const [eventToken, setEventToken] = useState("");
  const [companyLogo, setCompanyLogo] = useState(null);
  const [websiteUrl, setWebsiteUrl] = useState("");
  const [disclaimerUrl, setDisclaimerUrl] = useState(null);
  const [privacyPolicyUrl, setPrivacyPolicyUrl] = useState(null);
  const [eventIndication, setEventIndication] = useState("");
  const [productIndication, setProductIndication] = useState("");
  const [mail, setMail] = useState("");
  const [productId, setProductId] = useState(0);
  const [eventId, setEventId] = useState(0);
  const [userId, setUserId] = useState(0);
  
  const fetchData = async () => {
    try {
      const response1 = await axios.get("/api/get_logo_redirect_url");
      setLogoRedirectUrl(response1.data.logoRedirectUrl);
      let productTokenValue = new URLSearchParams(window.location.search).get("product") || "";
      const eventTokenValue = new URLSearchParams(window.location.search).get("event") || "";
      if (!productTokenValue && !eventTokenValue) {
        return;
      }
      setProductToken(productTokenValue);
      setEventToken(eventTokenValue);
      const tokenValue = productTokenValue || eventTokenValue;
      const infoEndpoint = productTokenValue != "" ? "/api/get_product_info" : "/api/get_event_info";
      const response2 = await axios.get(`${infoEndpoint}?token=${tokenValue}`);
      setEventId(response2.data.eventId)
      if (productTokenValue) {
        setProductId(response2.data.productId) 
        setProductIndication(response2.data.product_indication);
      }else{
        setAppState("register")
      }
      setEventIndication(response2.data.event_indication);
      setCompanyLogo(response2.data.logo);
      setWebsiteUrl(response2.data.website_url);
      setDisclaimerUrl(response2.data.disclaimer_url);
      setPrivacyPolicyUrl(response2.data.privacy_policy_url);
      const response3 = await fetch("/api/get-session", {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({ eventId: response2.data.eventId, productId: response2.data.productId }),
        credentials: "include",
      });
      const data3 = await response3.json();
      if (data3.email) {
        setMail(data3.email);
        setAppState("initial");
      } else {
        setAppState("mail-form");
      }
    } catch (error) {
      console.error("Error fetching initial data or config:", error);
    }
  };

  return (
    <AppContext.Provider
      value={{
        appState,
        logoRedirectUrl,
        disclaimerUrl,
        privacyPolicyUrl,
        productToken,
        eventToken,
        companyLogo,
        websiteUrl,
        eventIndication,
        productIndication,
        mail,
        productId,
        eventId,
        userId,
        fetchData,
        setAppState,
        setMail,
        setUserId
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

export function useAppContext() {
  return useContext(AppContext);
}
