import React, { useState } from "react";
import { useAppContext } from "./Store/Store";
import "./assets/css/RegistrationForm.css";
import enFlag from "./assets/img/flags/en.png";
import frFlag from "./assets/img/flags/fr.png";
import deFlag from "./assets/img/flags/de.png";
import itFlag from "./assets/img/flags/it.png";
import { useNavigate, useLocation } from "react-router-dom";

export default function RegistrationForm() {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    setAppState,
    mail,
    productId,
    eventId,
    disclaimerUrl,
    privacyPolicyUrl,
    setUserId
  } = useAppContext();
  const [language, setLanguage] = useState("en");
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: mail,
    language: "",
    gender: "",
    dateOfBirth: "",
    agreeTest: false,
    agreeDisclaimer: false,
    agreeImageUse: false,
    agreeNewsletter: false,
  });

  const flags = {
    en: enFlag,
    fr: frFlag,
    de: deFlag,
    it: itFlag,
  };

  const translations = {
    en: {
      firstName: "First Name",
      lastName: "Last Name",
      email: "Email",
      language: "Language",
      gender: "Gender",
      dateOfBirth: "Date of Birth",
      agreeDisclaimer:
        "I acknowledge the <a href='{disclaimerUrl}' target='_blank' rel='noopener noreferrer'>disclaimer</a> and give my consent.",
      agreeImageUse:
        "I accept the use of my image reproduced in Nordica brand channels only for photos/videos produced during the event.",
      agreeNewsletter:
        "By clicking on the SUBMIT button, I declare that I am of legal age, have read and accepted the <a href='{privacyPolicyUrl}' target='_blank' rel='noopener noreferrer'>privacy policy</a>, and that my personal data will be processed for the purpose of responding to information requests and/or contact requests.",
      submit: "SUBMIT",
    },
    fr: {
      firstName: "Prénom",
      lastName: "Nom",
      email: "Email",
      language: "Langue",
      gender: "Sexe",
      dateOfBirth: "Date de naissance",
      agreeDisclaimer:
        "Je reconnais la <a href='{disclaimerUrl}' target='_blank' rel='noopener noreferrer'>clause de non-responsabilité</a> et donne mon consentement.",
      agreeImageUse:
        "J'accepte l'utilisation de mon image reproduite dans les canaux de la marque Nordica uniquement pour les photos/vidéos produites pendant l'événement.",
      agreeNewsletter:
        "En cliquant sur le bouton SOUMETTRE, je déclare être majeur, avoir lu et accepté la <a href='{privacyPolicyUrl}' target='_blank' rel='noopener noreferrer'>politique de confidentialité</a> et que mes données personnelles seront traitées dans le but de répondre aux demandes d'information et/ou aux demandes de contact.",
      submit: "SOUMETTRE",
    },
    de: {
      firstName: "Vorname",
      lastName: "Nachname",
      email: "E-Mail",
      language: "Sprache",
      gender: "Geschlecht",
      dateOfBirth: "Geburtsdatum",
      agreeDisclaimer:
        "Ich nehme die <a href='{disclaimerUrl}' target='_blank' rel='noopener noreferrer'>Haftungsfreistellung</a> zur Kenntnis und gebe mein Einverständnis.",
      agreeImageUse:
        "Ich akzeptiere die Verwendung meines abgebildeten Bildes in den Kanälen der Marke Nordica nur für Fotos/Videos, die während der Veranstaltung produziert wurden.",
      agreeNewsletter:
        "Indem ich auf die Schaltfläche ABSENDEN klicke, erkläre ich, dass ich volljährig bin, die <a href='{privacyPolicyUrl}' target='_blank' rel='noopener noreferrer'>Datenschutzerklärung</a> gelesen und akzeptiert habe und dass meine personenbezogenen Daten zum Zwecke der Beantwortung von Informationsanfragen und/oder Kontaktanfragen verarbeitet werden.",
      submit: "ABSENDEN",
    },
    it: {
      firstName: "Nome",
      lastName: "Cognome",
      email: "Email",
      language: "Lingua",
      gender: "Genere",
      dateOfBirth: "Data di nascita",
      agreeDisclaimer:
        "Prendo atto della <a href='{disclaimerUrl}' target='_blank' rel='noopener noreferrer'>dichiarazione di non responsabilità</a> e do il mio consenso.",
      agreeImageUse:
        "Accetto l'utilizzo della mia immagine riprodotta nei canali del marchio Nordica esclusivamente per le foto/video prodotti durante l'evento.",
      agreeNewsletter:
        "Cliccando sul pulsante INVIA, dichiaro di essere maggiorenne, di aver letto e accettato la <a href='{privacyPolicyUrl}' target='_blank' rel='noopener noreferrer'>politica sulla privacy</a> e che i miei dati personali saranno trattati al fine di rispondere alle richieste di informazioni e/o di contatto.",
      submit: "INVIA",
    },
  };

  const getTranslationWithUrls = (key) => {
    return translations[language][key]
      .replace("{disclaimerUrl}", disclaimerUrl)
      .replace("{privacyPolicyUrl}", privacyPolicyUrl);
  };

  const handleLanguageChange = (code) => {
    setLanguage(code);
  };

  const renderFlagOption = (code, label, imageUrl) => (
    <div
      key={code}
      value={code}
      onClick={() => handleLanguageChange(code)}
      style={{
        display: "flex",
        alignItems: "center",
        padding: "8px",
        cursor: "pointer",
      }}
    >
      <img
        src={imageUrl}
        alt={label}
        style={{ width: "20px", marginRight: "5px" }}
      />
      {label}
    </div>
  );

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch("/api/register-user", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ ...formData, productId, eventId }),
        credentials: "include",
      });
      if (response.ok) {
        const data = await response.json()
        setUserId(data.user_id)
        setAppState("initial")
        navigate("/", { state: { ...location.state } })
      } else {
        console.log("Error during user registration");
      }
    } catch (error) {
      console.log("Network error", error);
    }
  };


  const handleBack = () => {
    setAppState("mail-form");
    navigate("/", { state: { ...location.state } });
  };

  return (
    <div
      style={{
        marginTop: "30px",
        overflowY: "auto",
        maxHeight: "100vh",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "20px",
          position: "relative",
        }}
      >
        <button
          onClick={handleBack}
          style={{ position: "absolute", top: 0, left: 0 }}
        >
          Back
        </button>
        <div style={{ display: "flex", justifyContent: "center" }}>
          {renderFlagOption("en", "English", flags.en)}
          {renderFlagOption("fr", "Français", flags.fr)}
          {renderFlagOption("de", "Deutsch", flags.de)}
          {renderFlagOption("it", "Italiano", flags.it)}
        </div>
      </div>
      <form
        className="registration-form"
        onSubmit={handleSubmit}
        style={{ marginBottom: "50px" }}
      >
        <div className="form-row">
          <div className="form-group">
            <label>{translations[language].firstName} *</label>
            <input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>{translations[language].lastName} *</label>
            <input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              required
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <label>{translations[language].email} *</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>{translations[language].language} *</label>
            <select
              name="language"
              value={formData.language}
              onChange={handleChange}
              required
            >
              <option value="">--select an item--</option>
              <option value="Italian">Italian</option>
              <option value="English">English</option>
              <option value="German">German</option>
              <option value="French">French</option>
            </select>
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <label>{translations[language].gender} *</label>
            <select
              name="gender"
              value={formData.gender}
              onChange={handleChange}
              required
            >
              <option value="">--select an item--</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Other">Other</option>
            </select>
          </div>
          <div className="form-group">
            <label>{translations[language].dateOfBirth} *</label>
            <input
              type="date"
              name="dateOfBirth"
              value={formData.dateOfBirth}
              onChange={handleChange}
              required
            />
          </div>
        </div>
        <div className="form-group">
          <label
            dangerouslySetInnerHTML={{
              __html: getTranslationWithUrls("agreeDisclaimer"),
            }}
          />
          <input
            type="checkbox"
            name="agreeDisclaimer"
            checked={formData.agreeDisclaimer}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label>{translations[language].agreeImageUse} *</label>
          <input
            type="checkbox"
            name="agreeImageUse"
            checked={formData.agreeImageUse}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label
            dangerouslySetInnerHTML={{
              __html: getTranslationWithUrls("agreeNewsletter"),
            }}
          />
          <input
            type="checkbox"
            name="agreeNewsletter"
            checked={formData.agreeNewsletter}
            onChange={handleChange}
          />
        </div>
        <button type="submit">{translations[language].submit}</button>
      </form>
    </div>
  );
}