import React, { useEffect, useState } from "react"
import axios from "axios"
import { useAppContext } from "./Store/Store"
import { useNavigate, useLocation } from "react-router-dom"
import stopRecordGif from "./assets/gifs/stop-recording-red.gif"
import startRecordSvg from "./assets/img/start-recording.svg"
import StarRating from "./assets/js/StarRating"
import RecordRTC from "recordrtc"

export default function Body() {
  const { appState, setAppState, productToken, mail, setMail, productId, eventId, userId, setUserId } = useAppContext()
  const [recorder, setRecorder] = useState(null)
  const [microphoneInput, setMicrophoneInput] = useState(null)
  const [rating, setRating] = useState(0)
  const [showTextReview, setShowTextReview] = useState(false)
  const [textReview, setTextReview] = useState("")
  const [isTextMode, setIsTextMode] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()

  const checkMail = async (email) => {
    try {
      const res = await axios.post("/api/check-mail", { email, eventId, productId }, { withCredentials: true })
      if (res.data.user_id !== false) {
        setUserId(res.data.user_id)
        return true
      } else {
        return false
      }
    } catch (err) {
      throw new Error("Mail check failed")
    }
  }


  useEffect(() => {
    if (appState === "register") {
      navigate("/register", { state: { ...location.state } })
    }
  }, [appState])

  const handleClick = async () => {
    if (appState === "mail-form") {
      try {
        const exists = await checkMail(mail)
        if (exists) {
          setAppState("initial")
          navigate("/", { state: { ...location.state } })
        } else {
          setAppState("register")
        }
      } catch (err) { }
    } else if (appState === "initial") {
      setAppState("recording")
      await getMicrophoneInput()
    } else if (appState === "recording") {
      if (recorder) {
        setAppState("waiting-stars")
        await stopMicrophoneInput()
      }
    } else if (appState === "waiting-stars") {
      if (rating > 0) {
        sendReview()
        setTimeout(() => {
          setAppState("processing")
        }, 300)
      }
    }
  }

  useEffect(() => {
    if (appState === "waiting-stars" && rating > 0) {
      handleClick()
    }
  }, [rating])

  useEffect(() => {
    if (appState === "processing") {
      const timer = setTimeout(() => {
        setAppState("initial")
        setRating(0)
        setMicrophoneInput(null)
        setRecorder(null)
        setShowTextReview(false)
        setTextReview("")
        setIsTextMode(false)
      }, 3000)
      return () => clearTimeout(timer)
    }
  }, [appState, setAppState])

  const getMicrophoneInput = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
      const supportedMimeType = "audio/webm;codecs=opus"
      const newRecorder = new RecordRTC(stream, {
        type: "audio",
        mimeType: supportedMimeType,
        checkForInactiveTracks: true,
      })
      newRecorder.startRecording()
      setRecorder(newRecorder)
    } catch (error) { }
  }

  const stopMicrophoneInput = async () => {
    recorder.stopRecording(() => {
      const blob = recorder.getBlob()
      setMicrophoneInput(blob)
    })
  }

  const sendReview = async () => {
    try {
      if (isTextMode) {
        const payload = {
          productId: productId,
          text: textReview,
          rating: rating,
          userId: userId,
        };
        await axios.post("/api/send_to_insightgpt", payload);
      } else {
        const formData = new FormData();
        formData.append("audio", microphoneInput, `recording_${Date.now()}.webm`);
        formData.append("rating", rating);
        const transcriptionResponse = await axios.post("/api/transcribe-audio", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
        const transcribedText = transcriptionResponse.data.transcription;
        const payload = {
          productId: productId,
          text: transcribedText,
          rating: rating,
          userId: userId,
        };
        await axios.post("/api/send_to_insightgpt", payload);
      }
    } catch (error) {
      console.error("Failed to send review:", error);
    }
  };  

  const handleSendTextReview = () => {
    setAppState("waiting-stars")
    setShowTextReview(false)
  }

  return (
    <div
      className="main"
      style={{ position: "relative", width: "100%", height: "59.5%" }}
    >
      <div
        style={{
          position: "absolute",
          top: "0%",
          height: "19%",
          width: "100%",
        }}
      ></div>
      <div
        className="review-actions"
        style={{
          position: "absolute",
          top: "19%",
          height: "68%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {!showTextReview ? (
          <>
            <div
              id="audio-review"
              className="review-block"
              style={{
                height: "75.5%",
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  height: "50%",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "5vh",
                }}
              >
                {appState === "mail-form" && (
                  <form
                    onSubmit={(e) => {
                      e.preventDefault()
                      handleClick()
                    }}
                    style={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div className="mail-block">
                      <input
                        type="email"
                        className="mail-input"
                        placeholder="Enter your email..."
                        value={mail}
                        onChange={(e) => setMail(e.target.value)}
                        required
                      />
                    </div>
                    <button
                      type="submit"
                      style={{
                        height: "25%",
                        marginTop: "15px",
                        width: "34vh",
                        borderRadius: "7px",
                        padding: "0 20px",
                        fontSize: "90%",
                        fontWeight: "bold",
                        color: "white",
                        background:
                          "linear-gradient(to right, #6991F8 0%, #07296a 100%)",
                        border: "none",
                        cursor: "pointer",
                        boxShadow: "0 5px 5px #6991F8",
                      }}
                    >
                      Start
                    </button>
                  </form>
                )}

                {appState === "initial" && (
                  <>
                    {productToken != "" ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={startRecordSvg}
                          alt="Start Recording"
                          style={{
                            maxHeight: "100%",
                            maxWidth: "100%",
                            cursor: "pointer",
                          }}
                          onClick={handleClick}
                        />
                        <div
                          style={{
                            marginTop: "2vh",
                            fontWeight: "bold",
                            fontSize: "100%",
                            color: "#07296A",
                          }}
                        >
                          Start Recording
                        </div>
                      </div>
                    )
                      : (<div class="registration-complete-msg">REGISTRATION COMPLETE, REVIEW PRODUCTS ON THE FIELD!</div>)} </>
                )}

                {appState === "recording" && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={stopRecordGif}
                      alt="Stop Recording"
                      style={{
                        maxHeight: "100%",
                        maxWidth: "100%",
                        cursor: "pointer",
                      }}
                      onClick={handleClick}
                    />
                    <div
                      style={{
                        marginTop: "10%",
                        fontWeight: "bold",
                        fontSize: "100%",
                        color: "#84071E",
                      }}
                    >
                      Stop Recording
                    </div>
                  </div>
                )}
                {appState === "waiting-stars" &&
                  (!isTextMode || (isTextMode && !showTextReview)) && (
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <StarRating rating={rating} setRating={setRating} />
                      <div
                        style={{
                          marginTop: "10px",
                          fontWeight: "bold",
                          fontSize: "100%",
                        }}
                      >
                        Evaluate your experience
                      </div>
                    </div>
                  )}
                {appState === "processing" && (
                  <div
                    style={{
                      fontWeight: "bold",
                      fontSize: "140%",
                      marginTop: "5%",
                    }}
                  >
                    FeedBack Sent
                  </div>
                )}
              </div>
            </div>

            {appState === "initial" && (
              <>
                {productToken != "" && (
                  <div
                    className="button-block"
                    style={{
                      height: "24.5%",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <button
                      style={{
                        height: "4.6vh",
                        width: "34vh",
                        borderRadius: "7px",
                        padding: "0 20px",
                        fontSize: "90%",
                        fontWeight: "bold",
                        color: "white",
                        background:
                          "linear-gradient(to right, #6991F8 0%, #07296a 100%)",
                        border: "none",
                        cursor: "pointer",
                        boxShadow: "0 5px 5px #6991F8",
                      }}
                      onClick={() => {
                        setShowTextReview(true)
                        setTextReview("")
                        setIsTextMode(true)
                      }}
                    >
                      Write Text Review
                    </button>
                  </div>
                )}</>
            )}

          </>
        ) : (
          <>
            <div className="text-review-block">
              <textarea
                className="text-review-input"
                placeholder="Write your text review here..."
                value={textReview}
                onChange={(e) => setTextReview(e.target.value)}
              ></textarea>
            </div>
            <div
              className="button-block"
              style={{
                height: "24.5%",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <button
                disabled={!textReview.trim()}
                style={
                  textReview.trim()
                    ? {
                      height: "4.6vh",
                      width: "34vh",
                      borderRadius: "4px",
                      padding: "0 20px",
                      fontSize: "90%",
                      fontWeight: "bold",
                      color: "#fff",
                      background:
                        "linear-gradient(to right, #6991F8 0%, #07296a 100%)",
                      border: "none",
                      cursor: "pointer",
                      boxShadow: "0 5px 5px rgba(7, 41, 106, 0.5)",
                      marginTop: "15px",
                    }
                    : {
                      height: "4.6vh",
                      width: "34vh",
                      borderRadius: "4px",
                      padding: "0 20px",
                      fontSize: "90%",
                      fontWeight: "bold",
                      color: "#07296A",
                      background: "#e4e5e9",
                      border: "none",
                      cursor: "not-allowed",
                      boxShadow: "0 5px 5px rgba(7, 41, 106, 0.5)",
                      marginTop: "15px",
                    }
                }
                onClick={handleSendTextReview}
              >
                Send text review
              </button>
              <button
                onClick={() => {
                  setAppState("initial")
                  setShowTextReview(false)
                  setTextReview("")
                  setIsTextMode(false)
                  setRating(0)
                  setRecorder(null)
                  setMicrophoneInput(null)
                }}
                style={{
                  fontSize: "9px",
                  textDecoration: "underline",
                  color: "#07296A",
                  background: "transparent",
                  border: "none",
                  cursor: "pointer",
                  marginTop: "7px",
                }}
              >
                Back
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  )
}
