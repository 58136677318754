import React, { useEffect } from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import Header from "./Header"
import Body from "./Body"
import Footer from "./Footer"
import "./assets/css/style.css"
import { AppProvider, useAppContext } from "./Store/Store"
import RegistrationForm from "./RegistrationForm"
import EmployeeLogin from "./EmployeeLogin"
import EmployeeDashboard from "./EmployeeDashboard"
import { BrowserRouter, Route, Routes } from "react-router-dom"

function AppContent() {
  const { appState, fetchData, eventToken, productToken } = useAppContext()

  useEffect(() => {
    fetchData()
  }, [])

  if (appState === "processing") {
    return (
      <div id="root" className="show-gradient">
        <Header />
        <Body />
        <Footer />
      </div>
    )
  }

  if (eventToken === "" && productToken === "") {
    return (
      <div id="root" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
        <div class="registration-complete-msg">NO TOKEN PROVIDED!</div>
      </div>
    )
  }

  return (
    <div id="root">
      <Header />
      <Body />
      <Footer />
    </div>
  )
}

export default function App() {
  return (
    <AppProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<AppContent />} />
          <Route path="/employee-login" element={<EmployeeLogin />} />
          <Route path="/employee-dashboard" element={<EmployeeDashboard />} />
          <Route path="/register" element={<RegistrationForm />} />
        </Routes>
      </BrowserRouter>
    </AppProvider>
  )
}

